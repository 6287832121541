html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

progress {
  vertical-align: baseline;
}

[hidden], template {
  display: none;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

a:active, a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

code, kbd, pre, samp {
  font-family: SFMono-Regular, Consolas, Roboto Mono, Droid Sans Mono, Liberation Mono, Menlo, Courier, monospace;
  font-size: 1em;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

button, input, optgroup, select, textarea {
  font: inherit;
  margin: 0;
}

optgroup {
  font-weight: 700;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[type="reset"], [type="submit"], button, html [type="button"] {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring, button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

html {
  box-sizing: border-box;
  font: 100% / 1.6em georgia, serif;
  overflow-y: scroll;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  word-wrap: break-word;
  font-kerning: normal;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt";
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: normal;
}

img {
  max-width: 100%;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

h1 {
  color: inherit;
  text-rendering: optimizelegibility;
  margin: 0 0 1.45rem;
  padding: 0;
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 1.1;
}

h2 {
  color: inherit;
  text-rendering: optimizelegibility;
  margin: 0 0 1.45rem;
  padding: 0;
  font-size: 1.62671rem;
  font-weight: 700;
  line-height: 1.1;
}

h3 {
  color: inherit;
  text-rendering: optimizelegibility;
  margin: 2rem 0 1.45rem;
  padding: 0;
  font-size: 1.38316rem;
  font-weight: 500;
  line-height: 1.1;
}

h4 {
  color: inherit;
  text-rendering: optimizelegibility;
  margin: 2rem 0 1.45rem;
  padding: 0;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.1;
}

h5 {
  color: inherit;
  text-rendering: optimizelegibility;
  margin: 2rem 0 1.45rem;
  padding: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.1;
}

h6 {
  color: inherit;
  text-rendering: optimizelegibility;
  margin: 0 0 1.45rem;
  padding: 0;
  font-size: .85028rem;
  font-weight: 500;
  line-height: 1.1;
}

hgroup {
  margin: 0 0 1.45rem;
  padding: 0;
}

ul, ol {
  margin: 0 0 1.45rem 1.45rem;
  padding: 0;
  list-style-image: none;
  list-style-position: outside;
}

dl, dd, p, figure {
  margin: 0 0 1.45rem;
  padding: 0;
}

pre {
  word-wrap: normal;
  white-space: pre-wrap;
  background: rgba(0, 0, 0, .04);
  border-radius: 3px;
  margin: 0 0 1.45rem;
  padding: 1.45rem;
  font-size: .85rem;
  line-height: 1.42;
  overflow: auto;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin: 0 0 1.45rem;
  padding: 0;
  font-size: 1rem;
  line-height: 1.45rem;
}

fieldset {
  margin: 0 0 1.45rem;
  padding: 0;
}

blockquote {
  margin: 0 1.45rem 1.45rem;
  padding: 0;
}

form, noscript, iframe {
  margin: 0 0 1.45rem;
  padding: 0;
}

hr {
  height: 1px;
  background: rgba(0, 0, 0, .2);
  border: none;
  margin: 4rem 0 0;
  padding: 0;
}

address {
  margin: 0 0 1.45rem;
  padding: 0;
}

b, strong, dt, th {
  font-weight: bold;
}

li {
  margin-bottom: .725rem;
}

ol li, ul li {
  padding-left: 0;
}

li > ol, li > ul {
  margin-top: .725rem;
  margin-bottom: .725rem;
  margin-left: 1.45rem;
}

blockquote :last-child, li :last-child, p :last-child {
  margin-bottom: 0;
}

li > p {
  margin-bottom: .725rem;
}

code {
  font-size: 1em;
  line-height: 1.45em;
}

kbd, samp {
  font-size: .85rem;
  line-height: 1.45rem;
}

abbr, acronym {
  cursor: help;
  border-bottom: 1px dotted rgba(0, 0, 0, .5);
}

abbr[title] {
  cursor: help;
  border-bottom: 1px dotted rgba(0, 0, 0, .5);
  text-decoration: none;
}

thead {
  text-align: left;
}

td, th {
  text-align: left;
  font-feature-settings: "tnum";
  -moz-font-feature-settings: "tnum";
  -ms-font-feature-settings: "tnum";
  -webkit-font-feature-settings: "tnum";
  border-bottom: 1px solid rgba(193, 174, 174, .12);
  padding: .725rem .96667rem calc(.725rem - 1px);
}

th:first-child, td:first-child {
  padding-left: 0;
}

th:last-child, td:last-child {
  padding-right: 0;
}

tt {
  color: #968af6;
  background-color: #2b2834;
  border-radius: 2px;
  padding: .2em;
  font-family: SFMono-Regular, Consolas, Roboto Mono, Droid Sans Mono, Liberation Mono, Menlo, Courier, monospace;
}

code {
  background-color: rgba(0, 0, 0, .04);
  border-radius: 3px;
  padding: .2em;
  font-family: SFMono-Regular, Consolas, Roboto Mono, Droid Sans Mono, Liberation Mono, Menlo, Courier, monospace;
}

pre code {
  background: none;
  line-height: 1.42;
}

code:before, tt:before, tt:after, pre code:before, pre code:after, pre tt:before, pre tt:after {
  content: "";
}

@media only screen and (max-width: 480px) {
  html {
    font-size: 100%;
  }
}

#root {
  margin: 0;
  padding: 0;
  display: block;
}

#root .page-Content {
  color: #222;
  background-color: #fff;
  padding: 24px 8px 12px;
  display: flex;
}

#root .page-Content h1 {
  font-size: 50px;
}

#root .page-Content pre {
  max-width: 100%;
  white-space: pre-wrap;
  background-color: #fff;
  border: 1px solid rgba(25, 25, 25, .05);
  border-radius: .25rem;
  padding: 1.2rem 1rem 0;
  overflow-x: scroll;
}

#root .page-Content a {
  fill: #b2b2b2;
  color: #00f;
}

#root .page-Content a:hover {
  fill: #1c1ce1;
  text-decoration: underline;
}

#root .page-Content a:visited {
  color: #88f;
}

#root .page-Content article {
  max-width: 1280px;
  width: 75%;
}

#root .page-Content article img {
  max-width: 100%;
}

#root .page-Content article table {
  background: #fff;
}

#root .page-Content article table tr {
  border-bottom: 1px solid #7f7f7f;
}

#root .page-Content article table tr th, #root .page-Content article table tr td {
  padding-left: 8px;
  padding-right: 2px;
}

#root .page-Content aside {
  max-width: 25%;
  min-width: 12rem;
}

#root .page-Content pre {
  padding-top: 0;
}

@media (max-width: 1024px) {
  #root .page-Content article {
    max-width: 100%;
    width: 100%;
  }

  #root .page-Content aside {
    display: none;
  }
}

body.theme-Dark #root .page-Content {
  color: #fff;
  background-color: #000;
}

body.theme-Dark #root .page-Content article table {
  background: #222;
}

body.theme-Dark #root .page-Content article table tr {
  border-bottom: 1px solid #b2b2b2;
}

body.theme-Dark #root .page-Content code {
  background-color: rgba(255, 255, 255, .2);
}

body.theme-Dark #root .page-Content pre {
  background-color: #ddd;
  border: 1px solid rgba(255, 255, 255, .05);
}

body.theme-Dark #root .page-Content pre code {
  background-color: rgba(0, 0, 0, 0);
}

body.theme-Dark #root .page-Content a {
  fill: #b2b2b2;
  color: #8cf;
}

body.theme-Dark #root .page-Content a:hover {
  fill: #ff70d2;
}

body.theme-Dark #root .page-Content a:visited {
  color: #aef;
}

#Navigation {
  z-index: 1000;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

#Navigation > .container {
  height: 4.75rem;
  box-sizing: border-box;
  color: #333;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  justify-content: center;
  padding: 1rem 2rem;
  display: flex;
}

#Navigation > .container > .area, #Navigation > .container > .area > .nav-Content {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

#Navigation > .container > .area > .nav-Content > ul.nav-LeftItems {
  align-items: center;
  margin: 0 0 0 2rem;
  list-style-type: none;
  list-style-image: none;
  display: flex;
}

#Navigation > .container > .area > .nav-Content > ul.nav-LeftItems > li.nav-ListItem {
  white-space: nowrap;
  color: #333;
  margin: 0;
}

#Navigation > .container > .area > .nav-Content > ul.nav-LeftItems > li.nav-ListItem > .nav-DropdownTitle {
  cursor: pointer;
  align-items: center;
  margin-right: 1.5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: flex;
}

#Navigation > .container > .area > .nav-Content > ul.nav-LeftItems > li.nav-ListItem > .nav-DropdownTitle > .nav-StyledIcon {
  -webkit-transform: rotateZ(0);
  transform: rotateZ(0);
  transition: all .2s ease-in-out;
}

#Navigation > .container > .area > .nav-Content > ul.nav-LeftItems > li.nav-ListItem:hover > .nav-DropdownTitle, #Navigation > .container > .area > .nav-Content > ul.nav-LeftItems > li.nav-ListItem .active > .nav-DropdownTitle {
  color: #1c1ce1;
}

#Navigation > .container > .area > .nav-Content > ul.nav-LeftItems > li.nav-ListItem:hover > .nav-DropdownTitle > .nav-StyledIcon, #Navigation > .container > .area > .nav-Content > ul.nav-LeftItems > li.nav-ListItem .active > .nav-DropdownTitle > .nav-StyledIcon {
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}

#Navigation > .container > .area > .nav-Content .nav-RightItems {
  justify-content: space-between;
  align-items: center;
  margin: 0;
  display: flex;
}

#Navigation > .container > .area > .nav-Content .link-Internal {
  align-items: center;
  margin-left: 1rem;
  margin-right: 0;
  text-decoration: none;
  display: flex;
}

#Navigation > .container > .area > .nav-Content .link-Internal svg:not(:root) {
  width: 24px;
  height: 24px;
  transition: all 80ms ease-in-out;
}

#Navigation > .container > .area > .nav-Content .link-Internal svg:not(:root).nav-Dark {
  fill: #b2b2b2;
}

#Navigation > .container > .area > .nav-Content .link-Internal svg:not(:root).nav-Dark:hover, #Navigation > .container > .area > .nav-Content .link-Internal svg:not(:root).nav-Dark:active {
  width: 28px;
  height: 28px;
  fill: #1c1ce1;
  margin-right: -4px;
}

#Navigation > .container > .area > .nav-Content .nav-Span {
  color: #333;
  padding-left: 1.2rem;
  font-weight: bold;
}

#Navigation > .container > .area > .nav-Content .nav-Span:hover, #Navigation > .container > .area > .nav-Content .nav-Span:active {
  color: #1c1ce1;
}

#Navigation > .container > .area > .nav-Content .nav-ThemeToggle {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  color: inherit;
  font: inherit;
  padding: initial;
  cursor: pointer;
  background: none;
  border: none;
  align-items: center;
  margin-left: 1rem;
  display: flex;
  position: relative;
}

#Navigation > .container > .area > .nav-Content .nav-ThemeToggle svg:not(:root) {
  transition: all 80ms ease-in-out;
}

#Navigation > .container > .area > .nav-Content .nav-ThemeToggle svg:not(:root).nav-Light {
  fill: #b2b2b2;
}

#Navigation > .container > .area > .nav-Content .nav-RightLink {
  align-items: center;
  margin-left: 1rem;
  margin-right: 0;
  text-decoration: none;
  display: flex;
}

#Navigation > .container > .area .nav-SearchRoot {
  gap: 1em;
  display: grid;
  position: relative;
}

#Navigation > .container > .area .nav-SearchRoot .form-Input {
  border-radius: .25em;
  margin: 0;
  position: relative;
}

#Navigation > .container > .area .nav-SearchRoot .form-Input .nav-Icon {
  fill: #b2b2b2;
  margin-top: -12px;
  position: absolute;
  top: 50%;
  left: 6px;
  right: 6px;
  overflow: hidden;
}

#Navigation > .container > .area .nav-SearchRoot .form-Input #header-search {
  color: #333;
  width: 100%;
  background: #fff;
  border: 1px solid #7f7f7f;
  border-radius: .25em;
  padding: .5rem 2rem .5rem .5rem;
}

@media only screen and (min-width: 1024px) {
  #Navigation > .container > .area .nav-SearchRoot .form-Input #header-search {
    padding-left: 2rem;
  }
}

#Navigation > .container > .area .nav-SearchRoot .input-SearchSlash {
  color: #333;
  border: 1px solid #7f7f7f;
  border-radius: .25em;
  margin-bottom: 0;
  padding: 0 6px;
  display: inline-block;
  position: absolute;
  top: 20%;
  right: 6px;
}

#Navigation > .container > .area .nav-SearchRoot .nav-SearchHitsWrapper {
  max-height: 80vh;
  z-index: 2;
  width: 80vw;
  max-width: 30em;
  background: #fff;
  border-radius: .25em;
  padding: .5rem;
  display: none;
  position: absolute;
  top: calc(100% + .5em);
  right: 0;
  overflow: scroll;
  box-shadow: 0 0 5px;
}

#Navigation > .container > .area .nav-SearchRoot .nav-SearchHitsWrapper .ais-Hits-list {
  margin: 0;
  list-style: none;
}

#Navigation > a {
  align-items: center;
  text-decoration: none;
  display: flex;
}

@media (max-width: 1024px) {
  #Navigation > .container > .area > .nav-Content > ul.nav-LeftItems > li, #Navigation > .container > .area > .nav-Content > ul.nav-RightItems .nav-SearchRoot {
    display: none;
  }
}

body.theme-Dark #Navigation > .container {
  color: #f2f2f2;
  background-color: #222;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

body.theme-Dark #Navigation > .container > .area > .nav-Content > ul > li.nav-ListItem {
  color: #f2f2f2;
}

body.theme-Dark #Navigation > .container > .area > .nav-Content > ul > li.nav-ListItem:hover > .nav-DropdownTitle, body.theme-Dark #Navigation > .container > .area > .nav-Content > ul > li.nav-ListItem .active > .nav-DropdownTitle {
  color: #ff70d2;
}

body.theme-Dark #Navigation > .container > .area > .nav-Content .link-Internal svg:not(:root).nav-Dark {
  fill: #b2b2b2;
}

body.theme-Dark #Navigation > .container > .area > .nav-Content .link-Internal svg:not(:root).nav-Dark:hover, body.theme-Dark #Navigation > .container > .area > .nav-Content .link-Internal svg:not(:root).nav-Dark:active {
  fill: #ff70d2;
}

body.theme-Dark #Navigation > .container > .area > .nav-Content .nav-Span {
  color: #f2f2f2;
}

body.theme-Dark #Navigation > .container > .area > .nav-Content .nav-Span:hover, body.theme-Dark #Navigation > .container > .area > .nav-Content .nav-Span:active {
  color: #ff70d2;
}

body.theme-Dark #Navigation > .container > .area > .nav-Content .nav-ThemeToggle svg:not(:root).nav-Light, body.theme-Dark #Navigation > .container > .area .nav-SearchRoot .form-Input .nav-Icon {
  fill: #b2b2b2;
}

body.theme-Dark #Navigation > .container > .area .nav-SearchRoot .form-Input #header-search {
  color: #f2f2f2;
  background: #222;
  border: 1px solid #b2b2b2;
}

@media only screen and (min-width: 1024px) {
  body.theme-Dark #Navigation > .container > .area .nav-SearchRoot .form-Input #header-search {
    padding-left: 2rem;
  }
}

body.theme-Dark #Navigation > .container > .area .nav-SearchRoot .input-SearchSlash {
  color: #f2f2f2;
  border: 1px solid #b2b2b2;
}

body.theme-Dark #Navigation > .container > .area .nav-SearchRoot .nav-SearchHitsWrapper {
  background: #222;
}

#PageNavi {
  min-width: 12rem;
  height: calc(100vh - 80px);
  padding: 1rem 0 1rem 1rem;
  transition: -webkit-transform .2s, transform .2s;
  display: block;
  position: -webkit-sticky;
  position: sticky;
  top: 5.25rem;
  overflow-y: hidden;
}

#PageNavi > .container {
  height: 100%;
  grid-template-rows: 30px 1fr;
  grid-template-columns: 100%;
  padding: 0;
  display: grid;
  overflow-y: hidden;
}

#PageNavi > .container > .area.navi-Title {
  grid-area: 1 / 1;
}

#PageNavi > .container > .area.navi-List {
  grid-area: 2 / 1;
  margin-top: 0;
  margin-left: 0;
  padding: 0;
  overflow-y: auto;
}

#PageNavi > .container > .area.navi-List > .navi-Goto {
  cursor: pointer;
}

#PageNavi > .container > .area.navi-List > .navi-Goto.navi-Focus {
  cursor: default;
  color: #1c1ce1;
  font-weight: bold;
}

#PageNavi > .container > .area.navi-List h2 {
  margin-top: 18px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
}

#PageNavi > .container > .area.navi-List h3 {
  margin-top: 18px;
  margin-bottom: 8px;
  padding-left: 18px;
  font-size: 12px;
}

#PageNavi > .container > .area.navi-List h3.navi-Focus:before {
  content: "●";
  margin-left: -6px;
  margin-right: -6px;
  display: inline-block;
}

@media (max-width: 1024px) {
  #PageNavi {
    display: none;
  }
}

body.theme-Dark #PageNavi > .container > .area.navi-List > .navi-Goto.navi-Focus {
  color: #ff70d2;
}

#Footer {
  width: 100%;
  display: block;
  position: static;
}

#Footer > .container {
  color: #333;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, .1);
  padding: 1rem 2rem;
}

#Footer > .container > .area.foot-Top {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: .875rem;
  display: flex;
}

#Footer > .container > .area.foot-Top > .foot-LastUpdated {
  color: #b2b2b2;
}

#Footer > .container > .area.foot-Top > .foot-SocialIcons {
  margin: 1rem 0;
}

#Footer > .container > .area.foot-Top > .foot-SocialIcons .foot-SocialIcon {
  width: 2rem;
  fill: #b2b2b2;
  margin-left: 1rem;
}

#Footer > .container > .area.foot-LinkGrid {
  grid-template-columns: repeat(6, auto);
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 18px;
  display: grid;
}

#Footer > .container > .area.foot-LinkGrid > .foot-LinkSection > .foot-LinkSectionHeader {
  margin: 1.14em 0;
  font-size: .875rem;
  font-weight: bold;
  line-height: 1.6;
}

#Footer > .container > .area.foot-LinkGrid > .foot-LinkSection > ul.foot-List {
  margin-left: 0;
}

#Footer > .container > .area.foot-LinkGrid > .foot-LinkSection > ul.foot-List > .foot-ListItem {
  margin: 0 0 .725rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.6;
  list-style-type: none;
  list-style-image: none;
}

#Footer > .container > .area.foot-LinkGrid > .foot-LinkSection > ul.foot-List > .foot-ListItem > .link-Internal {
  color: #b2b2b2;
  text-decoration: none;
}

#Footer > .container > .area.foot-LinkGrid > .foot-LinkSection > ul.foot-List > .foot-ListItem > .link-Internal:hover {
  color: #1c1ce1;
}

#Footer > .container > .area.foot-Copyright {
  text-align: right;
  color: #333;
  padding-right: 18px;
  font-size: 80%;
}

body.theme-Dark #Footer > .container {
  color: #f2f2f2;
  background-color: #222;
  border-top: 1px solid rgba(0, 0, 0, .1);
}

body.theme-Dark #Footer > .container > .area.foot-Top > .foot-LastUpdated {
  color: #b2b2b2;
}

body.theme-Dark #Footer > .container > .area.foot-Top > .foot-SocialIcons .foot-SocialIcon {
  fill: #b2b2b2;
}

body.theme-Dark #Footer > .container > .area.foot-LinkGrid > .foot-LinkSection > .foot-List > .foot-ListItem > .link-Internal {
  color: #b2b2b2;
}

body.theme-Dark #Footer > .container > .area.foot-LinkGrid > .foot-LinkSection > .foot-List > .foot-ListItem > .link-Internal:hover {
  color: #ff70d2;
}

body.theme-Dark #Footer > .container > .area.foot-Copyright {
  color: #f2f2f2;
}

/*# sourceMappingURL=index.2a427977.css.map */
